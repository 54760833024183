<template>
  <div id="app">
    <!-- <div class="top_nav">
      <img src="./assets/img/datang_log.png" alt="大唐移动logo" />
    </div> -->
    <div class="content">
      <!-- <div class="left_nav" id="skin-select">
        <router-link to="/" @click="clibutn">通信网络认知</router-link>
        <span class="moveLeft" id="toggle" @click="moveLft"></span>
      </div> -->
      <div class="container wrap-fluid">
        <div class="containerTitle">
          <i class="Title_icon"></i>
          通信网络认知
          <span>
            <el-button class="submit" type="primary" @click="submitclick" v-show="buttonshow">提&emsp;交</el-button>
            <div id="timer" v-if="showif">
              <span class="alarm_clock"></span>
              <p id="count_down">{{ minutes }}:{{ seconds }}</p>
            </div>
            <!-- <el-button class="submit" type="primary" @click="lookAll">查&emsp;看</el-button> -->
          </span>
        </div>
        <div class="containerBox">
          <keep-alive>
            <router-view />
          </keep-alive>
        </div>
      </div>
    </div>

    <!-- <el-button  type="primary" @click="functiongetSubPost()">测试接口</el-button> -->
    <telecom ref="telecom" v-show="false"></telecom>
    <!-- <eng ref="eng" v-show="false"></eng>
    <inte ref="inte" v-show="false"></inte>
    <unicatio ref="unicatio" v-show="false"></unicatio> -->

  </div>
</template>

<script>

import { PageDispense, communicationScore, get_user_data } from "./api/topics";
import telecom from "./views/TelecomView.vue"
import eng from "./views/EngineeringView.vue"
import inte from "./views/Intelligent/Intelligent.vue"
import unicatio from "./views/unicatioHomePage/childrenPage/ancientunication.vue"
import $ from "jquery"; //先引入再使用
export default {
  // name: 'HomeView',
  data() {
    return {
      buttonshow: sessionStorage.getItem("key"),
      timertime: null,
      minutes: null,
      seconds: null,
      showif: false,
      seTime: null,
      dataTime: false,
      template_name:''

    };
  },
  components: { telecom, eng, inte, unicatio },

  created() { },
  methods: {
    setTimer1() {
      setTimeout(() => {
        let time = this.seTime * 60;
        const timer = setInterval(() => {
          time--
          this.timertime = time
          let minutes = String(Math.trunc(time / 60)).padStart(2, 0);
          let seconds = String(time % 60).padStart(2, 0);
          this.minutes = minutes
          this.seconds = seconds
          if (time == 0) {
            clearInterval(timer)
            const loading = this.$loading({
              lock: true,
              text: '正在提交',
              // spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
              this.buttonshow = false
              this.showif = false
              loading.close();
              this.$router.push({ name: "about" });
              // console.log(this.$route.path)
            }, 2000);
          }
        }, 1000);
      }, 500);
    },
    /*  testAjax (params){
      return axios({method: 'post',url: '/token_test',data:params,headers: {"z-token": this.user_token}})
    },
    functiongetSubPost() {
      let curentData = {"pre":"11"};
      testAjax(curentData).then((res) => {
        console.log(res);
      });
    }, */
    clibutn() {
      this.buttonshow = true;
    },
    moveLft() {
      var newWidth = $("#app").width() - 300;
      var newWidth1 = $("#app").width() - 50;
      if ($("#toggle").hasClass("active")) {
        $("#toggle").removeClass("active");
        $("#skin-select").animate(
          { left: "0px" },
          { duration: 100, queue: false }
        );
        $(".wrap-fluid").animate(
          { width: newWidth, left: "300px" },
          {
            duration: 100,
            queue: false,
            complete: function () {
              $(".wrap-fluid").width("calc(100% - 300px)");
            },
          }
        );
      } else {
        $("#toggle").addClass("active");
        $("#skin-select").animate(
          { left: "-250px" },
          { duration: 100, queue: false }
        );
        $(".wrap-fluid").animate(
          { width: newWidth1, left: "50px" },
          {
            duration: 100,
            queue: false,
            complete: function () {
              $(".wrap-fluid").width("calc(100% - 50px)");
            },
          }
        );
      }
      this.$forceUpdate();
    },
    submitclick() {//提交
      //提交前保存一下所有的选项
      // this.off = new Date();
      // this.$refs.telecom.getSave();
      // this.$refs.eng.getSave();
      // this.$refs.inte.preservationBtnName();
      // this.$refs.unicatio.keepBtn();
      this.$store.commit("sum_communication");
      this.$confirm("确认要保存数据并提交吗？", "信息", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        // this.$message({
        //   type: "success",
        //   message: "保存提交成功!",
        // });
        const loading = this.$loading({
              lock: true,
              text: '正在提交',
              // spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
        setTimeout(() => {
          this.$router.push({ name: "about" });
          this.buttonshow = false;
          this.showif = false;
          loading.close();
        }, 2000);
        // console.log(res.status)
      }).catch(() => {
        this.$message({
          type: "info",
          message: "取消",
        });
      });
    },
    lookAll() {
      let datatimes = new FormData();
      datatimes.append("task_id", this.$store.state.taskId);
      datatimes.append("template_id", this.$store.state.template_id);
      datatimes.append("user_id", this.$store.state.user_id);
      communicationScore(datatimes).then((res) => {
        alert(res.data.score)
      });
    },
    Refresh() {
      // console.log(this.buttonshow);
      if (this.buttonshow == "false") {
        this.buttonshow = false;
        if (this.buttonshow == "true") {
          this.buttonshow = true;
        }
      }
    },
    popstate() {
      // console.log(this.$route.name);
      if (this.$route.name == "about") {
        this.buttonshow = false;
      } else {
        this.buttonshow = true;
      }
    },
    // getStatus() {
    //   var time = ''
    //   window.addEventListener('message', function (e) {
    //     time = e.data.data
    //   }, false)

    //   return time;
    // }
  },
  // watch: {
  //   dataTime: {
  //     handler(newName, oldName) {
  //       console.log(newName,oldName)
  //       this.dataTime = this.getStatus()
  //     },
  //     immediate: true
  //   }
  // },
  mounted() {
    if (this.buttonshow == null) {
      this.buttonshow = true;
    }
    var that = this
    window.addEventListener('message', function (e) {
      if(e.data.data) {
        that.$refs.telecom.getSave();
        that.$refs.eng.getSave();
        that.$refs.inte.preservationBtnName();
        that.$router.push({ name: "about" });
      }
      console.log(e);
    }, false)
    this.Refresh();
    this.setTimer1();
  },
  created() {
    this.buttonshow = true
    if (
      window.location.href.split("=")[1] &&
      window.location.href.split("=")[2]
    ) {
      let url = window.location.search; //获取url中"?"符后的字串 ('?modFlag=business&role=1')
      let theRequest = new Object();
      if (url.indexOf("?") != -1) {
        let str = url.substr(1); //substr()方法返回从参数值开始到结束的字符串；
        let strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
        let taskId = theRequest.taskId;
        this.template_name = decodeURI(theRequest.templeteName);
        let user_token = theRequest.user_token;

        this.$store.state.taskId = taskId;
        this.$store.state.template_name = this.template_name;
        this.$store.state.user_token = user_token;
        this.$store.state.user_id = theRequest.userId;
      }
    }

    // 创建vm实例后执行
    // 浏览器控制按钮前进后退触发函数
    window.addEventListener("popstate", this.popstate, false);

    //获取公共参数

    let taskId = this.$store.state.taskId;
    // let taskInfoName = this.$store.state.template_name ;
    let taskInfoName = this.template_name;
    let dataPageDispense = { taskId, taskInfoName };
    PageDispense(dataPageDispense).then((res) => {
      this.seTime = JSON.parse(res.data.taskInfo).duration
      if (this.seTime != null) {
        this.showif = true
      }
      this.$store.state.grade_id = res.data.grade_id;
      this.$store.state.taskInfo = JSON.parse(res.data.taskInfo);
      this.$store.state.template_id = this.$store.state.taskInfo.templete_id;
    });


    let datatimes = new FormData();
    datatimes.append("user_id", this.$store.state.user_id);
    get_user_data(datatimes).then((res) => {
      let parseG = JSON.parse(res.data.user_data)
      this.$store.state.realname = parseG.realname
      // console.log(this.$store.state.realname)
    });


  },
  destroyed() {
    // 销毁vm组件
    // 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener("popstate", this.popstate, false);
  },
};
</script>

<style lang="scss" scope>
body {
  margin: 0px;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  outline: none;
  color: #666;
}

#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.top_nav {
  width: 100%;
  height: 74px;
  line-height: 74px;
  box-sizing: border-box;
  padding: 10px;
  background-color: #4078cc;

  img {
    display: block;
    height: 100%;
  }
}

$height: 74px;
$width: 300px;

.content {
  position: relative;
  width: 100%;
  height: calc(100vh - #{$height}); //编译差别写法

  .left_nav {
    position: absolute;
    left: 0px;
    width: 300px;
    height: 100%;
    background-color: #eee;

    a {
      display: block;
      width: 100%;
      height: 35px;
      line-height: 35px;
      box-sizing: border-box;
      padding: 5px 10px;
      // color:#FFF;
      font-weight: 600;
      font-size: 18px;
    }

    .moveLeft {
      position: absolute;
      right: 0px;
      top: 50%;
      z-index: 10;
      width: 27px;
      height: 20px;
      background-color: blue;
      cursor: pointer;
      background-image: url(./assets/img/mainPage/left_skin.png);
      background-size: cover;
    }

    .active {
      transform-origin: center center;
      transform: rotate(-180deg) !important;
      -webkit-transform: rotate(-180deg) !important;
    }
  }

  .container {
    position: absolute;
    // left: 300px;
    // width: calc(100% - #{$width});
    width: 100%;

    // height: 100%;
    .containerTitle {
      height: 52px;
      line-height: 52px;
      box-sizing: border-box;
      padding-left: 10px;
      font-weight: 500;
      color: #1c4c82 !important;
      font-family: "Open Sans";
      font-size: 16px;
      font-weight: normal;
      background: -webkit-gradient(linear,
          0 0,
          0 100%,
          from(#ededed),
          to(#ededed));
      border-bottom: 1px solid #a6a6a1;
    }

    $heightS: 126px;

    .containerBox {
      width: 100%;
      // height: calc(100vh - #{$heightS});
      position: relative;

      .bgPic {
        display: block;
        width: 100%;
        // height:100%;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: -1;
      }
    }
  }
}

.submit {
  margin-top: 0.6%;
  float: right;
  width: 5%;
  margin-right: 2%;
  height: 36px !important;
  font-size: calc(100vw * 16 / 1920) !important;
  background: #007cc2 !important;
}

.Title_icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("../src/assets/img/imgdevelopment/Title_row.png");
  background-size: cover;
  vertical-align: text-bottom;
}

* {
  border: none;
}

#timer {
  width: 160px;
  height: 42px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #F4F5F5;
  border: 1px solid #2460b5;
  box-sizing: border-box;
  padding: 4px 12px;
  margin-top: 6px;
  float: right;
  margin-right: 2%;
}

.alarm_clock {
  display: inline-block;
  width: 36px;
  height: 35px;
  background-image: url(./assets/img/imgdevelopment/alarm_clock.png);
  background-size: cover;

}

#count_down {
  display: inline-block;
  float: right;
  width: 70%;
  line-height: 34px;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}
.el-loading-spinner .path {
  stroke: rgb(13, 161, 219);
}
</style>

